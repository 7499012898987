<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Actividades"></header-title>
      <v-card-title>
        <v-text-field
          class="mr-1"
          label="Buscar"
          v-model="options.search"
          append-icon="mdi-magnify"
          @input="changeSearch"
          hide-details
          single-line
          clearable
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn type="button" color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="activities"
        :loading="loading"
        :page.sync="options.page"
        :items-per-page="options.itemsPerPage"
        hide-default-footer
        calculate-widths
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{
              (options.page - 1) * options.itemsPerPage +
              (activities.indexOf(item) + 1)
            }}
          </td>
        </template>
        <template v-slot:[`item.date_limit`]="{ item }">
          <td>
            {{ item.fecha_limite | formatdate }}
          </td>
        </template>
        <template v-slot:[`item.files`]="{ item }">
          <td>
            <span v-if="item.plantilla">
              <a
                :href="`${serve}gerencia/plantilla/${item.plantilla}`"
                target="_blank"
                >ver o descargar</a
              >
            </span>
            <span class="grey--text" v-else>sin archivo</span>
          </td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip
                small
                color="green lighten-4"
                @click="changeActivity(item.id)"
                >activo</v-chip
              >
            </span>
            <span v-else>
              <v-chip
                small
                color="red lighten-4"
                @click="changeActivity(item.id)"
                >inactivo</v-chip
              >
            </span>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="editActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmDelete(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
      <div class="v-data-footer">
        <v-row>
          <v-col cols="12" sm="4">
            <div class="v-data-footer__select">
              Filas por página:
              <v-select
                :items="[10, 20, 30, 50, 100]"
                v-model="options.itemsPerPage"
                @change="changePerPage()"
                hide-details
                class="my-0"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="8" class="text-center">
            <v-pagination
              v-model="options.page"
              :length="options.pageCount"
              :total-visible="5"
              @input="changePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="rdialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Actividad</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Dirigido a *"
                    :items="roles"
                    v-model="activity.roles_id"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[
                      (v) => (!!v && v.length != 0) || 'Seleccione una opción',
                    ]"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Tipo de información *"
                    :items="type_information"
                    v-model="activity.tipo_informacion_id"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Seleccione una opción']"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="text"
                    label="Descripción *"
                    v-model="activity.descripcion"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="menu_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        label="Fecha límite"
                        append-icon="mdi-calendar"
                        :rules="[(v) => !!v || 'El campo es requerido']"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="activity.fecha_limite"
                      @input="menu_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    label="Plantilla(s)"
                    @click="pickFile"
                    v-model="template_name"
                    append-icon="mdi-attachment"
                    readonly
                  ></v-text-field>
                  <input
                    type="file"
                    style="display: none"
                    ref="template_file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.7zip"
                    @change="onFilePicked"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    label="Estado"
                    :items="states"
                    v-model="activity.estado"
                    item-text="description"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createActivity()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateActivity()"
              >Actualizar</v-btn
            >
            <v-btn color="red" text @click.native="rdialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <dialog-confirm
      :mymodal="cdialog"
      title="Alerta"
      message="¿Esta seguro(a) de eliminar el registro?"
      :btn_load="btn_loading"
      @confirm="deleteActivity"
      @close="closeDialog"
    />
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import DialogConfirm from "@/components/DialogConfirm";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import Service from "../../services/request";
import { mapActions } from "vuex";
export default {
  name: "activity",
  components: {
    HeaderTitle,
    DialogConfirm,
  },
  data() {
    return {
      loading: false,
      btn_loading: false,
      menu_date: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Tipo información ", value: "tipo_informacion.descripcion" },
        { text: "Descripción ", value: "descripcion" },
        { text: "Fecha límite ", value: "date_limit" },
        { text: "Plantilla ", value: "files", sortable: false },
        { text: "Estado ", value: "status" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      type_information: [],
      roles: [],
      activities: [],
      activity: {
        id: "",
        tipo_informacion_id: null,
        roles_id: [],
        descripcion: "",
        fecha_limite: "",
        plantilla: null,
        location: "",
        estado: false,
      },
      options: {
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      states: [],
      rdialog: false,
      cdialog: false,
      mode: true,
      template_name: "",
      serve: "",
    };
  },
  computed: {
    dateFormatted: {
      get() {
        if (!this.activity.fecha_limite) return null;
        const [year, month, day] = this.activity.fecha_limite.split("-");
        return `${day}/${month}/${year}`;
      },
      set() {
        return null;
      },
    },
  },
  created() {
    this.getActivityParameter();
    this.serve = Endpoint.getServe();
  },
  mounted() {
    const user = Storage.getUser();
    if (user && user.rid == 1) {
      this.states = Service.getStatus();
      this.getActivities();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    pickFile() {
      this.$refs.template_file.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].size < 2057157) {
          this.template_name = files[0].name;
          this.activity.plantilla = files[0];
        } else {
          this.$refs.template_file.value = "";
          this.template_name = "";
          this.activity.plantilla = null;
          this.toast({
            color: "info",
            text: "El archivo no debe superar el peso permitido de 2 MB",
          });
        }
      } else {
        this.template_name = "";
        this.activity.plantilla = null;
      }
    },
    receiveDate(date) {
      this.activity.fecha_limite = date;
    },
    getActivityParameter() {
      Service.svcActivityRegisterParameter("get")
        .then((response) => {
          this.type_information = response.data.tipo_informacion;
          this.roles = response.data.roles;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    getActivities() {
      this.loading = true;
      Service.svcActivityPaginate("post", this.options)
        .then((response) => {
          this.loading = false;
          this.activities = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount = Math.ceil(
              response.data.count / this.options.itemsPerPage
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDialog() {
      this.activity = {
        id: "",
        tipo_informacion_id: null,
        roles_id: [],
        descripcion: "",
        fecha_limite: "",
        plantilla: null,
        location: "gerencia/plantilla",
        estado: false,
      };
      this.template_name = "";
      this.mode = true;
      if (this.$refs.form) this.$refs.form.reset();
      if (this.$refs.template_file) this.$refs.template_file.value = "";
      this.rdialog = true;
    },
    createActivity() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcActivity("post", this.activity, this.template_name)
          .then((response) => {
            this.btn_loading = false;
            if (response.status === 201) {
              this.template_name = "";
              if (this.$refs.template_file) this.$refs.template_file.value = "";
              this.toast({ color: "success", text: response.data });
              this.rdialog = false;
              this.getActivities();
            } else {
              this.toast({ color: "error", text: response.data });
            }
          })
          .catch(() => {
            this.btn_loading = false;
            console.error("Error al guardar");
          });
      }
    },

    editActivity(data) {
      this.activity = data;
      this.mode = false;
      this.rdialog = true;
    },

    updateActivity() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        this.activity.location = "gerencia/plantilla";
        Service.svcActivity("put", this.activity, this.template_name)
          .then((response) => {
            this.btn_loading = false;
            this.template_name = "";
            if (this.$refs.template_file) this.$refs.template_file.value = "";
            this.toast({ color: "success", text: response.data });
            this.rdialog = false;
            this.getActivities();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast({ color: "error", text: "No se pudo actualizar" });
          });
      }
    },

    changeActivity(id) {
      this.activity.id = id;
      Service.svcChangeActivity("put", this.activity)
        .then((response) => {
          this.toast({ color: "success", text: response.data });
          this.getActivities();
        })
        .catch(() => {
          this.toast({
            color: "error",
            text: "Registro en uso o no existe",
          });
        });
    },

    confirmDelete(id) {
      this.activity.id = id;
      this.cdialog = true;
    },

    deleteActivity() {
      this.btn_loading = true;
      Service.svcActivity("delete", this.activity)
        .then((response) => {
          this.btn_loading = false;
          if (response.status === 204) {
            this.cdialog = false;
            this.toast({ color: "success", text: "Registro eliminado" });
            this.getActivities();
          } else {
            this.toast({
              color: "error",
              text: "Registro en uso o no existe",
            });
          }
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast({
            color: "error",
            text: "Registro en uso o no existe",
          });
        });
    },

    closeDialog() {
      this.cdialog = false;
    },

    changeSearch() {
      this.getActivities();
    },

    changePerPage() {
      this.options.page = 1;
      this.getActivities();
    },

    changePage() {
      this.getActivities();
    },
  },
};
</script>
